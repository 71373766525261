<template>
  <CyButton
    :variant="$attrs.variant || 'tertiary'"
    theme="primary"
    icon="edit"
    icon-only
    class="cy-edit-btn"
    @click.native="$emit('click')"/>
</template>

<script>
export default {
  name: 'CyEditButton',
}
</script>

<style lang="scss" scoped>
.v-btn.cy-btn.cy-btn__variant--tertiary.cy-edit-btn {
  &:active,
  &:focus,
  &:hover {
    ::v-deep .v-btn__content > .v-icon {
      color: cy-get-color("primary", "light-1");
    }
  }
}
</style>
